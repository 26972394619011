export default [
    {
        "x": -0.518753290,
        "y": -0.161286592,
        "z": -0.2908088862,
        "visibility": 0.99980920
    },
    {
        "x": -0.529396712,
        "y": -0.194162294,
        "z": -0.3149436712,
        "visibility": 0.999716520
    },
    {
        "x": -0.52962672,
        "y": -0.194632604,
        "z": -0.314456999,
        "visibility": 0.999636054
    },
    {
        "x": -0.53006696,
        "y": -0.1947509646,
        "z": -0.3140342533,
        "visibility": 0.999768495
    },
    {
        "x": -0.534938991,
        "y": -0.199330389,
        "z": -0.2805247008,
        "visibility": 0.999647259
    },
    {
        "x": -0.53562092,
        "y": -0.2000607699,
        "z": -0.2816860377,
        "visibility": 0.999660432
    },
    {
        "x": -0.537099123,
        "y": -0.2005181163,
        "z": -0.280311733,
        "visibility": 0.999799013
    },
    {
        "x": -0.436521708,
        "y": -0.2518699169,
        "z": -0.359820604,
        "visibility": 0.999574840
    },
    {
        "x": -0.480825752,
        "y": -0.27290663,
        "z": -0.2191761136,
        "visibility": 0.99953985
    },
    {
        "x": -0.4720184504,
        "y": -0.1724745780,
        "z": -0.303648412,
        "visibility": 0.999895513
    },
    {
        "x": -0.4830692112,
        "y": -0.1776076555,
        "z": -0.2618837952,
        "visibility": 0.999809801
    },
    {
        "x": -0.2257940471,
        "y": -0.185548603,
        "z": -0.3663599789,
        "visibility": 0.999934434
    },
    {
        "x": -0.4080578,
        "y": -0.232462584,
        "z": -0.1007367521,
        "visibility": 0.999936103
    },
    {
        "x": -0.1545005142,
        "y": 0.0427091643,
        "z": -0.363973677,
        "visibility": 0.995372354
    },
    {
        "x": -0.3714077174,
        "y": -0.0165905151,
        "z": 0.03259017318,
        "visibility": 0.706869482
    },
    {
        "x": -0.1461444348,
        "y": 0.2715564668,
        "z": -0.3303701281,
        "visibility": 0.989867031
    },
    {
        "x": -0.428338915,
        "y": 0.2171590477,
        "z": 0.02249267324,
        "visibility": 0.791883349
    },
    {
        "x": -0.1221186071,
        "y": 0.329449057,
        "z": -0.3753193616,
        "visibility": 0.972563147
    },
    {
        "x": -0.4441420435,
        "y": 0.2548761367,
        "z": 0.02031319029,
        "visibility": 0.711071312
    },
    {
        "x": -0.1651530414,
        "y": 0.34175083,
        "z": -0.376935869,
        "visibility": 0.971030354
    },
    {
        "x": -0.453366279,
        "y": 0.27176102,
        "z": -0.0139079503,
        "visibility": 0.723208546
    },
    {
        "x": -0.1590860635,
        "y": 0.288692712,
        "z": -0.3340591192,
        "visibility": 0.952606081
    },
    {
        "x": -0.427535265,
        "y": 0.2327645570,
        "z": 0.00684119062,
        "visibility": 0.721317350
    },
    {
        "x": 0.0846294388,
        "y": -0.00514191994,
        "z": -0.0724537372,
        "visibility": 0.99919539
    },
    {
        "x": -0.0855734422,
        "y": 0.0050242841,
        "z": 0.0733750760,
        "visibility": 0.999159395
    },
    {
        "x": 0.1525021046,
        "y": 0.37977772,
        "z": -0.064112596,
        "visibility": 0.993740320
    },
    {
        "x": -0.2305235713,
        "y": 0.3237613439,
        "z": 0.01598816365,
        "visibility": 0.882316172
    },
    {
        "x": 0.253013461,
        "y": 0.667766153,
        "z": 0.05866063386,
        "visibility": 0.974941551
    },
    {
        "x": -0.2141409665,
        "y": 0.711136579,
        "z": 0.0829552188,
        "visibility": 0.863102972
    },
    {
        "x": 0.266243159,
        "y": 0.705345392,
        "z": 0.0647847652,
        "visibility": 0.795307278
    },
    {
        "x": -0.2128210365,
        "y": 0.761538147,
        "z": 0.0839114114,
        "visibility": 0.722259879
    },
    {
        "x": 0.215498924,
        "y": 0.79296344,
        "z": -0.00674026319,
        "visibility": 0.950717508
    },
    {
        "x": -0.318364232,
        "y": 0.800626754,
        "z": 0.0368755981,
        "visibility": 0.917162537
    }
]