export default [
    {
        "x": -0.02835999801,
        "y": -0.597501456,
        "z": -0.205234363,
        "visibility": 0.999993681
    },
    {
        "x": -0.018716355,
        "y": -0.637647211,
        "z": -0.1969659775,
        "visibility": 0.999988973
    },
    {
        "x": -0.01799583435,
        "y": -0.638046503,
        "z": -0.1963666379,
        "visibility": 0.999990344
    },
    {
        "x": -0.0184701103,
        "y": -0.638580918,
        "z": -0.196321979,
        "visibility": 0.999991297
    },
    {
        "x": -0.04858719557,
        "y": -0.62755048,
        "z": -0.180743724,
        "visibility": 0.999982714
    },
    {
        "x": -0.0481658130,
        "y": -0.628150403,
        "z": -0.18224905,
        "visibility": 0.999978423
    },
    {
        "x": -0.0480132773,
        "y": -0.629432439,
        "z": -0.18127879,
        "visibility": 0.999980211
    },
    {
        "x": 0.076236546,
        "y": -0.632290422,
        "z": -0.1290980279,
        "visibility": 0.999988079
    },
    {
        "x": -0.05667417868,
        "y": -0.60110765,
        "z": -0.0603510029,
        "visibility": 0.999950170
    },
    {
        "x": 0.01454365439,
        "y": -0.575848937,
        "z": -0.185023456,
        "visibility": 0.999985516
    },
    {
        "x": -0.02471670694,
        "y": -0.564551353,
        "z": -0.1629400700,
        "visibility": 0.999942243
    },
    {
        "x": 0.1786428391,
        "y": -0.4224169254,
        "z": -0.1252653896,
        "visibility": 0.999980926
    },
    {
        "x": -0.080465160,
        "y": -0.4318662881,
        "z": 0.0260147061,
        "visibility": 0.99985748
    },
    {
        "x": 0.1788374185,
        "y": -0.1796881854,
        "z": -0.1512093394,
        "visibility": 0.995077431
    },
    {
        "x": -0.1666668504,
        "y": -0.2662328481,
        "z": -0.0635512024,
        "visibility": 0.903100371
    },
    {
        "x": 0.1749076992,
        "y": 0.0783968046,
        "z": -0.149875953,
        "visibility": 0.971875488
    },
    {
        "x": -0.01517048850,
        "y": -0.35241353,
        "z": -0.293770939,
        "visibility": 0.952591001
    },
    {
        "x": 0.180675193,
        "y": 0.1490624696,
        "z": -0.1548633277,
        "visibility": 0.932570397
    },
    {
        "x": 0.0465019606,
        "y": -0.3604202270,
        "z": -0.329692840,
        "visibility": 0.912283301
    },
    {
        "x": 0.1639817059,
        "y": 0.1613802909,
        "z": -0.1752887964,
        "visibility": 0.937900543
    },
    {
        "x": 0.072069182,
        "y": -0.400620520,
        "z": -0.3097239434,
        "visibility": 0.898520588
    },
    {
        "x": 0.1663399934,
        "y": 0.1005122885,
        "z": -0.1545401960,
        "visibility": 0.936107993
    },
    {
        "x": 0.01273241732,
        "y": -0.36734923,
        "z": -0.2917043566,
        "visibility": 0.866396725
    },
    {
        "x": 0.066597357,
        "y": 0.01752405427,
        "z": -0.0827871114,
        "visibility": 0.999975264
    },
    {
        "x": -0.0666451305,
        "y": -0.01771486550,
        "z": 0.0835234299,
        "visibility": 0.999969303
    },
    {
        "x": -0.3642690777,
        "y": 0.0361419394,
        "z": -0.194994509,
        "visibility": 0.973955154
    },
    {
        "x": -0.353664040,
        "y": 0.0726926773,
        "z": 0.0490258522,
        "visibility": 0.730407118
    },
    {
        "x": -0.1418569684,
        "y": 0.409049630,
        "z": -0.1204329207,
        "visibility": 0.967745900
    },
    {
        "x": -0.2224551439,
        "y": 0.438587039,
        "z": 0.1065505668,
        "visibility": 0.803317785
    },
    {
        "x": -0.1117663532,
        "y": 0.4551044106,
        "z": -0.1083542853,
        "visibility": 0.931575834
    },
    {
        "x": -0.2090157568,
        "y": 0.483715742,
        "z": 0.10871183,
        "visibility": 0.71693247
    },
    {
        "x": -0.1912526339,
        "y": 0.588861763,
        "z": -0.1027957126,
        "visibility": 0.969986319
    },
    {
        "x": -0.279375106,
        "y": 0.584117233,
        "z": 0.0847197398,
        "visibility": 0.82884919
    }
]