export default [
    {
        "x": 0.020951269194483757,
        "y": -0.5757455825805664,
        "z": -0.24501125514507294,
        "visibility": 0.9999827742576599
    },
    {
        "x": 0.020391972735524178,
        "y": -0.6155315637588501,
        "z": -0.22404910624027252,
        "visibility": 0.9999687671661377
    },
    {
        "x": 0.02054903283715248,
        "y": -0.6161257028579712,
        "z": -0.2232588678598404,
        "visibility": 0.9999673366546631
    },
    {
        "x": 0.02085842750966549,
        "y": -0.6164370179176331,
        "z": -0.22349031269550323,
        "visibility": 0.9999712109565735
    },
    {
        "x": -0.008831318467855453,
        "y": -0.607150137424469,
        "z": -0.23485195636749268,
        "visibility": 0.9999569058418274
    },
    {
        "x": -0.008248700760304928,
        "y": -0.6079323291778564,
        "z": -0.23582804203033447,
        "visibility": 0.9999597072601318
    },
    {
        "x": -0.008286983706057072,
        "y": -0.6094092130661011,
        "z": -0.2347298264503479,
        "visibility": 0.999957263469696
    },
    {
        "x": 0.030153991654515266,
        "y": -0.6179220676422119,
        "z": -0.09961188584566116,
        "visibility": 0.9999677538871765
    },
    {
        "x": -0.10447577387094498,
        "y": -0.588119626045227,
        "z": -0.16026286780834198,
        "visibility": 0.9999261498451233
    },
    {
        "x": 0.027655785903334618,
        "y": -0.5573949813842773,
        "z": -0.20296011865139008,
        "visibility": 0.99998939037323
    },
    {
        "x": -0.010684257373213768,
        "y": -0.5469164848327637,
        "z": -0.2177945375442505,
        "visibility": 0.9999829530715942
    },
    {
        "x": 0.12569212913513184,
        "y": -0.4819495677947998,
        "z": -0.003945090342313051,
        "visibility": 0.9999498128890991
    },
    {
        "x": -0.17053377628326416,
        "y": -0.4109455645084381,
        "z": -0.16251571476459503,
        "visibility": 0.9994096159934998
    },
    {
        "x": 0.37244707345962524,
        "y": -0.46841469407081604,
        "z": -0.04722597077488899,
        "visibility": 0.9908413887023926
    },
    {
        "x": -0.09001563489437103,
        "y": -0.25454282760620117,
        "z": -0.33001095056533813,
        "visibility": 0.9915997385978699
    },
    {
        "x": 0.6330242156982422,
        "y": -0.49792951345443726,
        "z": -0.17288613319396973,
        "visibility": 0.9870273470878601
    },
    {
        "x": 0.046622809022665024,
        "y": -0.38583096861839294,
        "z": -0.49616554379463196,
        "visibility": 0.9913175702095032
    },
    {
        "x": 0.7007498741149902,
        "y": -0.5048907995223999,
        "z": -0.18915694952011108,
        "visibility": 0.969971776008606
    },
    {
        "x": 0.08488915115594864,
        "y": -0.4084303379058838,
        "z": -0.5391339659690857,
        "visibility": 0.9792665839195251
    },
    {
        "x": 0.698424220085144,
        "y": -0.5043277144432068,
        "z": -0.21598978340625763,
        "visibility": 0.9737367033958435
    },
    {
        "x": 0.08495531976222992,
        "y": -0.45262354612350464,
        "z": -0.5232102870941162,
        "visibility": 0.9779875874519348
    },
    {
        "x": 0.6440409421920776,
        "y": -0.49338531494140625,
        "z": -0.18657903373241425,
        "visibility": 0.9742995500564575
    },
    {
        "x": 0.05920308083295822,
        "y": -0.40449631214141846,
        "z": -0.49612778425216675,
        "visibility": 0.9643166661262512
    },
    {
        "x": 0.11432664096355438,
        "y": -0.013452261686325073,
        "z": 0.030893586575984955,
        "visibility": 0.999866783618927
    },
    {
        "x": -0.11430232971906662,
        "y": 0.013514506630599499,
        "z": -0.030099408701062202,
        "visibility": 0.9994907379150391
    },
    {
        "x": 0.24269306659698486,
        "y": 0.05097481235861778,
        "z": -0.33527854084968567,
        "visibility": 0.9970020055770874
    },
    {
        "x": -0.14118154346942902,
        "y": 0.0882914587855339,
        "z": -0.37061575055122375,
        "visibility": 0.9962642788887024
    },
    {
        "x": 0.2544045150279999,
        "y": 0.4035402834415436,
        "z": -0.2483799159526825,
        "visibility": 0.99238520860672
    },
    {
        "x": -0.08443288505077362,
        "y": 0.43826186656951904,
        "z": -0.2653670907020569,
        "visibility": 0.993824303150177
    },
    {
        "x": 0.257532000541687,
        "y": 0.44707784056663513,
        "z": -0.24474498629570007,
        "visibility": 0.9613009691238403
    },
    {
        "x": -0.07457427680492401,
        "y": 0.4824865162372589,
        "z": -0.2591093182563782,
        "visibility": 0.9577352404594421
    },
    {
        "x": 0.2714833617210388,
        "y": 0.5345062613487244,
        "z": -0.37114500999450684,
        "visibility": 0.9919488430023193
    },
    {
        "x": -0.0364592969417572,
        "y": 0.5720470547676086,
        "z": -0.3837011456489563,
        "visibility": 0.9917539358139038
    }
]