export default [
    {
        "x": -0.0946359038,
        "y": -0.662347376,
        "z": -0.0998754128,
        "visibility": 0.999753832
    },
    {
        "x": -0.0816448926,
        "y": -0.696668744,
        "z": -0.1113275066,
        "visibility": 0.999589681
    },
    {
        "x": -0.081186458,
        "y": -0.696952283,
        "z": -0.1107331290,
        "visibility": 0.999607920
    },
    {
        "x": -0.0814492180,
        "y": -0.697320222,
        "z": -0.1103340536,
        "visibility": 0.999623060
    },
    {
        "x": -0.0897751599,
        "y": -0.69563174,
        "z": -0.0804461017,
        "visibility": 0.999564409
    },
    {
        "x": -0.0893074050,
        "y": -0.696451306,
        "z": -0.0810823440,
        "visibility": 0.999461352
    },
    {
        "x": -0.0894598364,
        "y": -0.697885036,
        "z": -0.0801593363,
        "visibility": 0.999508440
    },
    {
        "x": 0.02326917834,
        "y": -0.676597416,
        "z": -0.1236805841,
        "visibility": 0.999403357
    },
    {
        "x": -0.0235222019,
        "y": -0.683409452,
        "z": 0.003670067293,
        "visibility": 0.999243855
    },
    {
        "x": -0.0509204119,
        "y": -0.636726379,
        "z": -0.1033599227,
        "visibility": 0.99953877
    },
    {
        "x": -0.0650343820,
        "y": -0.636952936,
        "z": -0.066329456,
        "visibility": 0.999108552
    },
    {
        "x": 0.0726632252,
        "y": -0.508569836,
        "z": -0.1879345476,
        "visibility": 0.999870479
    },
    {
        "x": -0.0195783078,
        "y": -0.50435340,
        "z": 0.0933088660,
        "visibility": 0.999087810
    },
    {
        "x": -0.1973495483,
        "y": -0.573140859,
        "z": -0.2910718917,
        "visibility": 0.998642325
    },
    {
        "x": -0.0857415348,
        "y": -0.3378800749,
        "z": 0.171868950,
        "visibility": 0.2721439898
    },
    {
        "x": -0.4510603249,
        "y": -0.604237139,
        "z": -0.2425983250,
        "visibility": 0.989101409
    },
    {
        "x": -0.1301762014,
        "y": -0.2023454904,
        "z": 0.099389694,
        "visibility": 0.458070009
    },
    {
        "x": -0.515022516,
        "y": -0.57368588,
        "z": -0.263893276,
        "visibility": 0.972261726
    },
    {
        "x": -0.130903691,
        "y": -0.1548494398,
        "z": 0.098226159,
        "visibility": 0.4566930532
    },
    {
        "x": -0.51331973,
        "y": -0.580736696,
        "z": -0.242934465,
        "visibility": 0.971399843
    },
    {
        "x": -0.122521795,
        "y": -0.166273549,
        "z": 0.0790962055,
        "visibility": 0.4714904129
    },
    {
        "x": -0.461955517,
        "y": -0.599640369,
        "z": -0.2319411635,
        "visibility": 0.954329669
    },
    {
        "x": -0.1225752234,
        "y": -0.1952560096,
        "z": 0.089567653,
        "visibility": 0.454740196
    },
    {
        "x": 0.06059939786,
        "y": 0.01020643860,
        "z": -0.084175236,
        "visibility": 0.999929249
    },
    {
        "x": -0.06060303002,
        "y": -0.01066081132,
        "z": 0.0851741805,
        "visibility": 0.999774098
    },
    {
        "x": 0.0989371836,
        "y": 0.408012092,
        "z": -0.02241766639,
        "visibility": 0.988486051
    },
    {
        "x": -0.00871641095,
        "y": 0.3538073897,
        "z": 0.2143146991,
        "visibility": 0.895908415
    },
    {
        "x": 0.1775775253,
        "y": 0.740568816,
        "z": 0.0581867955,
        "visibility": 0.995634257
    },
    {
        "x": 0.04709796234,
        "y": 0.68969196,
        "z": 0.372224569,
        "visibility": 0.941147923
    },
    {
        "x": 0.1845875978,
        "y": 0.784803092,
        "z": 0.0598489753,
        "visibility": 0.95993679
    },
    {
        "x": 0.0460014715,
        "y": 0.73549193,
        "z": 0.382290750,
        "visibility": 0.877429425
    },
    {
        "x": 0.0837159007,
        "y": 0.857628047,
        "z": -0.0371830761,
        "visibility": 0.994467318
    },
    {
        "x": -0.0594132393,
        "y": 0.811544239,
        "z": 0.3397961854,
        "visibility": 0.976976037
    }
]