export default [
    {
        "x": -0.011367978528141975,
        "y": -0.6454938054084778,
        "z": -0.2634355127811432,
        "visibility": 0.9999915957450867
    },
    {
        "x": -0.006319718901067972,
        "y": -0.6839049458503723,
        "z": -0.24550001323223114,
        "visibility": 0.9999583959579468
    },
    {
        "x": -0.005760744214057922,
        "y": -0.6843745708465576,
        "z": -0.24501188099384308,
        "visibility": 0.9999620914459229
    },
    {
        "x": -0.006051629316061735,
        "y": -0.6847636699676514,
        "z": -0.24511757493019104,
        "visibility": 0.9999521970748901
    },
    {
        "x": -0.038455452769994736,
        "y": -0.6761927008628845,
        "z": -0.241566002368927,
        "visibility": 0.9999663233757019
    },
    {
        "x": -0.03788531571626663,
        "y": -0.6771345138549805,
        "z": -0.24230904877185822,
        "visibility": 0.9999708533287048
    },
    {
        "x": -0.03793778270483017,
        "y": -0.6784417629241943,
        "z": -0.2413603663444519,
        "visibility": 0.9999663233757019
    },
    {
        "x": 0.0540803000330925,
        "y": -0.6735774278640747,
        "z": -0.13904832303524017,
        "visibility": 0.9999511241912842
    },
    {
        "x": -0.09100333601236343,
        "y": -0.6471142172813416,
        "z": -0.12976530194282532,
        "visibility": 0.999972403049469
    },
    {
        "x": 0.017854569479823112,
        "y": -0.6217281222343445,
        "z": -0.22695857286453247,
        "visibility": 0.9999744892120361
    },
    {
        "x": -0.025341209024190903,
        "y": -0.6127739548683167,
        "z": -0.22307735681533813,
        "visibility": 0.9999812245368958
    },
    {
        "x": 0.17766804993152618,
        "y": -0.5151689052581787,
        "z": -0.04920840635895729,
        "visibility": 0.999951958656311
    },
    {
        "x": -0.14838987588882446,
        "y": -0.4631366431713104,
        "z": -0.09223352372646332,
        "visibility": 0.9996791481971741
    },
    {
        "x": 0.3954410254955292,
        "y": -0.5207353234291077,
        "z": -0.032645732164382935,
        "visibility": 0.9967375993728638
    },
    {
        "x": -0.2113354504108429,
        "y": -0.23697292804718018,
        "z": -0.156281977891922,
        "visibility": 0.968843936920166
    },
    {
        "x": 0.6203265190124512,
        "y": -0.5609272718429565,
        "z": -0.12280316650867462,
        "visibility": 0.9952475428581238
    },
    {
        "x": -0.07722640782594681,
        "y": -0.12560471892356873,
        "z": -0.30316421389579773,
        "visibility": 0.9677784442901611
    },
    {
        "x": 0.6693859696388245,
        "y": -0.558663547039032,
        "z": -0.14423047006130219,
        "visibility": 0.9849804639816284
    },
    {
        "x": -0.02832653559744358,
        "y": -0.07100047916173935,
        "z": -0.33148133754730225,
        "visibility": 0.9283484220504761
    },
    {
        "x": 0.6760536432266235,
        "y": -0.5718289017677307,
        "z": -0.1746893972158432,
        "visibility": 0.9872292280197144
    },
    {
        "x": -0.0014406699920073152,
        "y": -0.10886075347661972,
        "z": -0.3400377035140991,
        "visibility": 0.9292905926704407
    },
    {
        "x": 0.6326472759246826,
        "y": -0.563181459903717,
        "z": -0.13735292851924896,
        "visibility": 0.9865779280662537
    },
    {
        "x": -0.05071210116147995,
        "y": -0.12826129794120789,
        "z": -0.31023064255714417,
        "visibility": 0.9064103364944458
    },
    {
        "x": 0.11177930235862732,
        "y": 0.007235171739012003,
        "z": -0.0076577505096793175,
        "visibility": 0.9998199939727783
    },
    {
        "x": -0.11215201020240784,
        "y": -0.00758935883641243,
        "z": 0.008440221659839153,
        "visibility": 0.9996531009674072
    },
    {
        "x": 0.03656427189707756,
        "y": 0.4264232814311981,
        "z": 0.030430585145950317,
        "visibility": 0.9932889342308044
    },
    {
        "x": -0.08316242694854736,
        "y": 0.4004024565219879,
        "z": 0.06453922390937805,
        "visibility": 0.9892327785491943
    },
    {
        "x": 0.04486265406012535,
        "y": 0.7541225552558899,
        "z": 0.2045072764158249,
        "visibility": 0.9893741011619568
    },
    {
        "x": -0.03797086328268051,
        "y": 0.7375466227531433,
        "z": 0.2482619732618332,
        "visibility": 0.988179087638855
    },
    {
        "x": 0.04787648841738701,
        "y": 0.7977781891822815,
        "z": 0.21276196837425232,
        "visibility": 0.8238789439201355
    },
    {
        "x": -0.03259717673063278,
        "y": 0.7813860177993774,
        "z": 0.2575484812259674,
        "visibility": 0.8797491192817688
    },
    {
        "x": 0.037280887365341187,
        "y": 0.8674953579902649,
        "z": 0.08248777687549591,
        "visibility": 0.9825663566589355
    },
    {
        "x": -0.06251498311758041,
        "y": 0.8502615690231323,
        "z": 0.14027903974056244,
        "visibility": 0.9860133528709412
    }
]