export default [
    {
        "x": 0.5950145125389099,
        "y": -0.19876819849014282,
        "z": -0.0002216753055108711,
        "visibility": 0.9999155402183533
    },
    {
        "x": 0.6303750276565552,
        "y": -0.1951812356710434,
        "z": -0.02148699201643467,
        "visibility": 0.9998964667320251
    },
    {
        "x": 0.6306961178779602,
        "y": -0.19492380321025848,
        "z": -0.02090141922235489,
        "visibility": 0.999896228313446
    },
    {
        "x": 0.6308547258377075,
        "y": -0.19519297778606415,
        "z": -0.020608795806765556,
        "visibility": 0.9999087452888489
    },
    {
        "x": 0.632172703742981,
        "y": -0.1945902705192566,
        "z": 0.011048628017306328,
        "visibility": 0.9998002648353577
    },
    {
        "x": 0.6332001686096191,
        "y": -0.194431871175766,
        "z": 0.010471933521330357,
        "visibility": 0.9997760057449341
    },
    {
        "x": 0.6343265771865845,
        "y": -0.19472022354602814,
        "z": 0.01141485944390297,
        "visibility": 0.9998399019241333
    },
    {
        "x": 0.6274487972259521,
        "y": -0.09926292300224304,
        "z": -0.07375418394804001,
        "visibility": 0.9998077750205994
    },
    {
        "x": 0.6484634280204773,
        "y": -0.10275561362504959,
        "z": 0.06165584921836853,
        "visibility": 0.9996805191040039
    },
    {
        "x": 0.5763093829154968,
        "y": -0.15455110371112823,
        "z": -0.017768576741218567,
        "visibility": 0.9998217821121216
    },
    {
        "x": 0.5798963904380798,
        "y": -0.15481813251972198,
        "z": 0.022368209436535835,
        "visibility": 0.9995468258857727
    },
    {
        "x": 0.48263248801231384,
        "y": -0.04191876947879791,
        "z": -0.16508431732654572,
        "visibility": 0.9998624324798584
    },
    {
        "x": 0.4875120222568512,
        "y": -0.06847736984491348,
        "z": 0.15801046788692474,
        "visibility": 0.9960831999778748
    },
    {
        "x": 0.44654351472854614,
        "y": -0.3087310791015625,
        "z": -0.21874623000621796,
        "visibility": 0.9950014352798462
    },
    {
        "x": 0.49768632650375366,
        "y": -0.3369714021682739,
        "z": 0.20191621780395508,
        "visibility": 0.4721233546733856
    },
    {
        "x": 0.4397980272769928,
        "y": -0.5374248027801514,
        "z": -0.19372989237308502,
        "visibility": 0.991714358329773
    },
    {
        "x": 0.4993442893028259,
        "y": -0.5806437134742737,
        "z": 0.15235324203968048,
        "visibility": 0.5857199430465698
    },
    {
        "x": 0.4425823986530304,
        "y": -0.5771847367286682,
        "z": -0.22249698638916016,
        "visibility": 0.9846222400665283
    },
    {
        "x": 0.5205742716789246,
        "y": -0.6140257716178894,
        "z": 0.16309422254562378,
        "visibility": 0.5507599711418152
    },
    {
        "x": 0.46386492252349854,
        "y": -0.5725445747375488,
        "z": -0.1965610682964325,
        "visibility": 0.983046293258667
    },
    {
        "x": 0.5297097563743591,
        "y": -0.610747218132019,
        "z": 0.13356925547122955,
        "visibility": 0.5610219836235046
    },
    {
        "x": 0.44775816798210144,
        "y": -0.5466144680976868,
        "z": -0.18042589724063873,
        "visibility": 0.9770840406417847
    },
    {
        "x": 0.5026512145996094,
        "y": -0.587310254573822,
        "z": 0.13572686910629272,
        "visibility": 0.5933820009231567
    },
    {
        "x": 0.006672991905361414,
        "y": 0.022254034876823425,
        "z": -0.09858021885156631,
        "visibility": 0.9997531175613403
    },
    {
        "x": -0.006339859217405319,
        "y": -0.022429333999753,
        "z": 0.09911995381116867,
        "visibility": 0.998921275138855
    },
    {
        "x": -0.4196622669696808,
        "y": 0.024109721183776855,
        "z": -0.06352881342172623,
        "visibility": 0.9553687572479248
    },
    {
        "x": -0.393145889043808,
        "y": -0.017217809334397316,
        "z": 0.15550029277801514,
        "visibility": 0.3412861227989197
    },
    {
        "x": -0.7818590998649597,
        "y": 0.10434698313474655,
        "z": -0.04210518300533295,
        "visibility": 0.9819573760032654
    },
    {
        "x": -0.7654291391372681,
        "y": 0.07186460494995117,
        "z": 0.23098984360694885,
        "visibility": 0.659467875957489
    },
    {
        "x": -0.8284677267074585,
        "y": 0.11292711645364761,
        "z": -0.04607772082090378,
        "visibility": 0.971375584602356
    },
    {
        "x": -0.8155460357666016,
        "y": 0.07405625283718109,
        "z": 0.23380392789840698,
        "visibility": 0.714307963848114
    },
    {
        "x": -0.880888819694519,
        "y": 0.014738350175321102,
        "z": -0.12423054128885269,
        "visibility": 0.9789025783538818
    },
    {
        "x": -0.879593551158905,
        "y": -0.028001373633742332,
        "z": 0.1870327740907669,
        "visibility": 0.8560599684715271
    }
]