import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from './views/IndexPage.vue'
import AdminPanel from './views/AdminPanel.vue'
import SessionPage from './views/SessionPage.vue';
import UserExercises from './views/UserExercises.vue';

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: IndexPage,
        },
        {
            path: '/admin',
            component: AdminPanel,
        },
        {
            path: '/admin/:id',
            component: SessionPage,
        },
        {
            path: '/user/:user',
            component: UserExercises,
        },
    ],
})
