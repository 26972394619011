export default [
    {
        "x": 0.6046550273895264,
        "y": -0.0984509140253067,
        "z": -0.01878969557583332,
        "visibility": 0.9991811513900757
    },
    {
        "x": 0.6388780474662781,
        "y": -0.09192202985286713,
        "z": -0.043759819120168686,
        "visibility": 0.9990198612213135
    },
    {
        "x": 0.6390078663825989,
        "y": -0.09172197431325912,
        "z": -0.04321999475359917,
        "visibility": 0.9988957047462463
    },
    {
        "x": 0.6394829750061035,
        "y": -0.09175931662321091,
        "z": -0.04287906363606453,
        "visibility": 0.9990577697753906
    },
    {
        "x": 0.6415903568267822,
        "y": -0.08902369439601898,
        "z": -0.009710167534649372,
        "visibility": 0.9986872673034668
    },
    {
        "x": 0.6426829695701599,
        "y": -0.08867312222719193,
        "z": -0.010373110882937908,
        "visibility": 0.9986283183097839
    },
    {
        "x": 0.6437821388244629,
        "y": -0.08893869817256927,
        "z": -0.009516538120806217,
        "visibility": 0.9991487264633179
    },
    {
        "x": 0.62871915102005,
        "y": 0.004518702160567045,
        "z": -0.10659932345151901,
        "visibility": 0.9981164932250977
    },
    {
        "x": 0.6571937203407288,
        "y": 0.012639204040169716,
        "z": 0.03696731477975845,
        "visibility": 0.9981492161750793
    },
    {
        "x": 0.582755982875824,
        "y": -0.05634193494915962,
        "z": -0.03970274701714516,
        "visibility": 0.9988106489181519
    },
    {
        "x": 0.5890313982963562,
        "y": -0.053820930421352386,
        "z": 0.002361658029258251,
        "visibility": 0.9983008503913879
    },
    {
        "x": 0.4666491448879242,
        "y": 0.04052253067493439,
        "z": -0.18924975395202637,
        "visibility": 0.9998201727867126
    },
    {
        "x": 0.4750739634037018,
        "y": 0.034310635179281235,
        "z": 0.13262994587421417,
        "visibility": 0.9994290471076965
    },
    {
        "x": 0.2889854609966278,
        "y": -0.1764635145664215,
        "z": -0.18634073436260223,
        "visibility": 0.946360170841217
    },
    {
        "x": 0.28629186749458313,
        "y": -0.1439516395330429,
        "z": 0.17097538709640503,
        "visibility": 0.16253863275051117
    },
    {
        "x": 0.17396877706050873,
        "y": -0.32497990131378174,
        "z": -0.09504462033510208,
        "visibility": 0.777953028678894
    },
    {
        "x": 0.14772745966911316,
        "y": -0.35270586609840393,
        "z": 0.12549686431884766,
        "visibility": 0.25721874833106995
    },
    {
        "x": 0.1383291333913803,
        "y": -0.3537844121456146,
        "z": -0.1084904596209526,
        "visibility": 0.7172765731811523
    },
    {
        "x": 0.12015468627214432,
        "y": -0.39395561814308167,
        "z": 0.13474734127521515,
        "visibility": 0.24569270014762878
    },
    {
        "x": 0.1451852172613144,
        "y": -0.36030927300453186,
        "z": -0.10260726511478424,
        "visibility": 0.715564489364624
    },
    {
        "x": 0.12526163458824158,
        "y": -0.40488681197166443,
        "z": 0.11478623002767563,
        "visibility": 0.2522156536579132
    },
    {
        "x": 0.16832542419433594,
        "y": -0.33240100741386414,
        "z": -0.08836057782173157,
        "visibility": 0.6764432191848755
    },
    {
        "x": 0.13991019129753113,
        "y": -0.36137935519218445,
        "z": 0.11326862126588821,
        "visibility": 0.26122093200683594
    },
    {
        "x": -0.003096825908869505,
        "y": 0.01879357360303402,
        "z": -0.09888763725757599,
        "visibility": 0.9995344877243042
    },
    {
        "x": 0.0035087994765490294,
        "y": -0.019100578501820564,
        "z": 0.09944143891334534,
        "visibility": 0.9992292523384094
    },
    {
        "x": -0.4064754247665405,
        "y": -0.019725872203707695,
        "z": -0.05604466050863266,
        "visibility": 0.8997036814689636
    },
    {
        "x": -0.37641093134880066,
        "y": -0.02464049682021141,
        "z": 0.15451790392398834,
        "visibility": 0.2970947325229645
    },
    {
        "x": -0.7785708904266357,
        "y": 0.027388762682676315,
        "z": -0.009885222651064396,
        "visibility": 0.9696571826934814
    },
    {
        "x": -0.755593478679657,
        "y": 0.015531404875218868,
        "z": 0.24220268428325653,
        "visibility": 0.6456363797187805
    },
    {
        "x": -0.8241148591041565,
        "y": 0.03309030085802078,
        "z": -0.010256027802824974,
        "visibility": 0.9425532221794128
    },
    {
        "x": -0.803346574306488,
        "y": 0.012202938087284565,
        "z": 0.24559392035007477,
        "visibility": 0.6567983627319336
    },
    {
        "x": -0.8857517838478088,
        "y": -0.0663856640458107,
        "z": -0.07119549065828323,
        "visibility": 0.9762563705444336
    },
    {
        "x": -0.8657337427139282,
        "y": -0.08483757078647614,
        "z": 0.21055841445922852,
        "visibility": 0.8463960289955139
    }
]