export default [
    {
        "x": -0.17731623351573944,
        "y": -0.5588080883026123,
        "z": 0.023376882076263428,
        "visibility": 0.9996728897094727
    },
    {
        "x": -0.18322841823101044,
        "y": -0.5937531590461731,
        "z": -0.0039012357592582703,
        "visibility": 0.999627947807312
    },
    {
        "x": -0.18309010565280914,
        "y": -0.5939492583274841,
        "z": -0.0036134454421699047,
        "visibility": 0.9996505379676819
    },
    {
        "x": -0.18321755528450012,
        "y": -0.5943799614906311,
        "z": -0.00337392371147871,
        "visibility": 0.9996764063835144
    },
    {
        "x": -0.17704419791698456,
        "y": -0.5974842309951782,
        "z": 0.03041655197739601,
        "visibility": 0.999518871307373
    },
    {
        "x": -0.1768675446510315,
        "y": -0.5985988974571228,
        "z": 0.02954169176518917,
        "visibility": 0.9995509386062622
    },
    {
        "x": -0.17713691294193268,
        "y": -0.5996981263160706,
        "z": 0.03068949468433857,
        "visibility": 0.9996781349182129
    },
    {
        "x": -0.09650513529777527,
        "y": -0.6099646091461182,
        "z": -0.0753958448767662,
        "visibility": 0.9995388984680176
    },
    {
        "x": -0.08044406771659851,
        "y": -0.6432323455810547,
        "z": 0.06898465007543564,
        "visibility": 0.9996377825737
    },
    {
        "x": -0.13335660099983215,
        "y": -0.5496682524681091,
        "z": 0.0009608623804524541,
        "visibility": 0.9995298385620117
    },
    {
        "x": -0.12866222858428955,
        "y": -0.556947648525238,
        "z": 0.043202441185712814,
        "visibility": 0.9992744326591492
    },
    {
        "x": -0.05326144024729729,
        "y": -0.4785543978214264,
        "z": -0.14856883883476257,
        "visibility": 0.9999744892120361
    },
    {
        "x": 0.04286658391356468,
        "y": -0.47875916957855225,
        "z": 0.16602998971939087,
        "visibility": 0.9994935989379883
    },
    {
        "x": -0.20498688519001007,
        "y": -0.26486361026763916,
        "z": -0.15917271375656128,
        "visibility": 0.9837643504142761
    },
    {
        "x": -0.019555553793907166,
        "y": -0.25423112511634827,
        "z": 0.22164596617221832,
        "visibility": 0.06635641306638718
    },
    {
        "x": -0.36843669414520264,
        "y": -0.13334962725639343,
        "z": -0.09342288970947266,
        "visibility": 0.9194424748420715
    },
    {
        "x": -0.1728030890226364,
        "y": -0.10424488037824631,
        "z": 0.1921563595533371,
        "visibility": 0.061374761164188385
    },
    {
        "x": -0.399686723947525,
        "y": -0.11273777484893799,
        "z": -0.11014988273382187,
        "visibility": 0.8997392654418945
    },
    {
        "x": -0.19073383510112762,
        "y": -0.08788703382015228,
        "z": 0.20497384667396545,
        "visibility": 0.0763855129480362
    },
    {
        "x": -0.40198078751564026,
        "y": -0.1273708939552307,
        "z": -0.09083123505115509,
        "visibility": 0.8952101469039917
    },
    {
        "x": -0.20928257703781128,
        "y": -0.09692754596471786,
        "z": 0.17703893780708313,
        "visibility": 0.07588238269090652
    },
    {
        "x": -0.37635940313339233,
        "y": -0.13312426209449768,
        "z": -0.08216586709022522,
        "visibility": 0.8648860454559326
    },
    {
        "x": -0.18332335352897644,
        "y": -0.10143101960420609,
        "z": 0.17865252494812012,
        "visibility": 0.07762644439935684
    },
    {
        "x": -0.014616620726883411,
        "y": -0.00004355215060058981,
        "z": -0.1066734716296196,
        "visibility": 0.9996499419212341
    },
    {
        "x": 0.014301070012152195,
        "y": -0.000252309488132596,
        "z": 0.10715731978416443,
        "visibility": 0.9982448220252991
    },
    {
        "x": -0.02483093924820423,
        "y": 0.3920658826828003,
        "z": -0.06873192638158798,
        "visibility": 0.9190021753311157
    },
    {
        "x": -0.002252053702250123,
        "y": 0.3698138892650604,
        "z": 0.1561930924654007,
        "visibility": 0.2872886061668396
    },
    {
        "x": 0.032220471650362015,
        "y": 0.7816678285598755,
        "z": -0.05683396756649017,
        "visibility": 0.9567283391952515
    },
    {
        "x": 0.04075551778078079,
        "y": 0.7568466663360596,
        "z": 0.2004353404045105,
        "visibility": 0.5406214594841003
    },
    {
        "x": 0.039968471974134445,
        "y": 0.8276384472846985,
        "z": -0.059353552758693695,
        "visibility": 0.9485795497894287
    },
    {
        "x": 0.03948419541120529,
        "y": 0.8055965900421143,
        "z": 0.20035861432552338,
        "visibility": 0.6326491236686707
    },
    {
        "x": -0.07266567647457123,
        "y": 0.8478072881698608,
        "z": -0.10509078949689865,
        "visibility": 0.9609274864196777
    },
    {
        "x": -0.06540855765342712,
        "y": 0.850704550743103,
        "z": 0.17923438549041748,
        "visibility": 0.7571763396263123
    }
]